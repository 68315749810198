@import 'src/styles/variables';

$fontColor: $white;

.text {
  color: $fontColor !important;
}

.layoutContainer {
  background: radial-gradient(94.96% 94.96% at -3.11% -1.41%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)
    #050015 !important;
}

.layoutContent * {
  color: $white;
}

// HEADER
.header {
  color: $white;
}

.logo {
  path {
    fill: $white;
  }
}

.lovePhrase {
  color: $white !important;
}

// FORM NAVIGATION
.formNavigation * {
  color: $white !important;
}

.backLink {
  path {
    fill: $white;
  }

  &:hover {
    color: $fontColor;

    svg path {
      fill: $white !important;
    }
  }
}

// FORM CONTAINER
.formContainer {
  background-color: rgba(255, 255, 255, 0.12) !important;
  border: 0;

  .subtitle {
    color: $fontColor !important;
  }
}

// INPUT
.inputLabel {
  color: $fontColor !important;
}

.input {
  background-color: rgba(242, 244, 247, 0.6) !important;
  border-color: transparent !important;
}

.button {
  background-color: $white !important;
  color: $blue500 !important;
}

// COMMON QUESTIONS
.commonQuestions {
  .questions {
    background-color: $black80;
    border: 0;
  }

  .selectArrow {
    path {
      stroke: $white;
    }
  }
}

// FOOTER
.needHelp * {
  color: $fontColor !important;
}

.chatIcon {
  path {
    fill: $white;
  }

  circle {
    fill: $blue500;
  }
}

.copyright {
  color: $fontColor;
}

.icon {
  path {
    fill: $white;
  }
}
